import React from 'react';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';
import styles from './styles.module.sass';


export default ({ image, title, subtitle, link }) => (
    <Grid item container xs={12} sm={12} md={12} lg={6} className={styles.blogContainer}>
      <div className={styles.imageWrapper}>
        <Img imgStyle={{ objectFit: 'cover' }}
            objectFit='cover'
            className={styles.image}
            fluid={image.fluid}
            alt={title}/>
      </div>
      <div className={styles.textWrap}>
        <Typography
            variant='h2'
            color='dark'
            weight='bold'
            className={styles.blogTitle}>
        {title}
        </Typography>
        <Typography 
            variant='subtitle2' 
            align='left' 
            weight='normal'
            className={styles.blogSubtitle}>
            {subtitle}
        </Typography>
        <div className={styles.buttonWrap}>
            <a href={link}
            target='_blank'
            rel='noreferrer'
            className={styles.button}>
            <Button className={styles.button}>
                {i18n('Read more')}
            </Button>
            </a>
        </div>
      </div>
    </Grid>
);