import React from 'react';
import { Grid } from '@material-ui/core';
import { Fade } from 'react-reveal';
import Img from 'gatsby-image/withIEPolyfill';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ img, text, delay }) => (
  <Grid item xs={6} sm={6} md={3} className={styles.iconContainer}>
    <Fade delay={delay}>
      <Img fluid={img.fluid}
        className={styles.imageContainer}
        objectFit='contain'
        alt={text}/>
      <Typography variant='subtitle2'
        align="center"
        color="dark"
        weight='bold'
        className={styles.textContainer}>
        {text}
      </Typography>
    </Fade>
  </Grid>
);