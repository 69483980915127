import React from 'react';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './styles.module.sass';
import Typography from '../../../../../components/Typography';

export default ({ fluid, children }) => (
  <Grid item xs={12} sm={6} md={3} className={styles.container}>
    <div className={styles.imageContainer}>
      <Img objectFit='contain'
        className={styles.imageContainer} 
        fluid={fluid}/>
    </div>
    <Typography variant='subtitle2' 
      align="center"
      className={styles.textContainer}>
      {children}
    </Typography>
  </Grid>
);