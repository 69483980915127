import React from 'react';
import { Grid } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { Fade } from 'react-reveal';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

const partners = [ 1, 2, 5, 3, 4 ];

export default () => (
  <StaticQuery
    query={graphql`
      query {
        partner1: imageSharp(fluid: {originalName: {eq: "Sponsor-1.png"}}) {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner2: imageSharp(fluid: {originalName: {eq: "Sponsor-2.png"}}) {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner3: imageSharp(fluid: {originalName: {eq: "Sponsor-3.png"}}) {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner4: imageSharp(fluid: {originalName: {eq: "Sponsor-4.png"}}) {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner5: imageSharp(fluid: {originalName: {eq: "Sponsor-5.png"}}) {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }      
    `}
    render={data => (
      <div className={styles.container}>
        <Typography variant='h5'
          weight='regular'
          className={styles.titleContainer}>
          {i18n('Hundreds of companies already use Fleet by OBI+')}
        </Typography>
        <Grid className={styles.icons}
          container justify="space-around">
          {partners.map(i => (
            <Grid item key={i} xs={2} className={styles.icon}>
              <Fade delay={100 * i}>
                <Img imgStyle={{ objectFit: 'contain'}}
                  objectFit='contain'
                  className={styles.image}
                  fluid={data[`partner${i}`].fluid}
                  alt={`partner ${i}`}/>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }/>
);