import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import { Fade } from 'react-reveal';
import i18n from '../../../../locale';
import Button from '../../../../components/Button';
import SectionWrapper from '../../../../components/SectionWrapper';
import Typography from '../../../../components/Typography';
import FooterItem from './FooterItem';
import styles from './styles.module.sass';
import Image from './Image';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        phone0: imageSharp(fluid: {
          originalName : {eq: "Main-Driver-App-1.jpg"}
        }) {
          fluid(maxWidth: 600, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone1: imageSharp(fluid: {
          originalName : {eq: "Main-Driver-App-2.jpg"}
        }) {
          fluid(maxWidth: 600, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone2: imageSharp(fluid: {
          originalName : {eq: "Main-Driver-App-3.jpg"}
        }) {
          fluid(maxWidth: 600, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        narrow: imageSharp(fluid: {
          originalName : {eq: "Main-Driver-App-Narrow.jpg"}
        }) {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={imgs => (
      <SectionWrapper>
        <Grid item container xs={12} md={12} xl={12}
          alignContent='center'
          justify='center'
          className={styles.container}>
          <Grid item container xs={12} sm={12} md={12} lg={3} xl={3}
            alignContent='center'
            alignItems='center'
            justify='center'
            className={styles.callToActionContainer}>
            <Typography variant='h4'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Show your employees trust while you keep the control')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Mobile app for every driver')}
            </Typography>
            <div className={clsx(
                styles.titleContainer,
                styles.buttonContainer
              )}>
              <Link to={`${i18n('_url:landing-fleet-features')}#${i18n('_hash:driver-app')}`}
                style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Read more')}
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={clsx(
              styles.imageContainer,
              styles.imageContainerNarrow
            )}>
            <Fade bottom>
              <Img objectFit='contain'
                className={styles.image}
                fluid={imgs.narrow.fluid} />
            </Fade>
          </Grid>
          <Image fluid={imgs.phone0.fluid}/>
          <Image fluid={imgs.phone1.fluid}
            delay={250}/>
          <Image fluid={imgs.phone2.fluid}
            delay={500}/>
        </Grid>
        <Grid container className={styles.footerContainer}
          alignContent='center'
          justify='flex-end'>
          <FooterItem text={i18n('Download a mobile application to view journeys and add notes')}/>
          <FooterItem text={i18n('Receive key vehicle notifications and communicate with your colleagues')}/>
          <FooterItem text={i18n('Automatic trip logging and categorization')}/>
        </Grid>
      </SectionWrapper>
    )}/>
);