import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ text }) => (
  <Grid item container xs={12} sm={12} md={4} lg={3} xl={3} justify='center'
    alignContent='center'
    alignItems='center'
    className={styles.footerItem}>
    <Typography variant='subtitle2'
      color='dark'
      weight='light'
      className={styles.footerItemText}>
      {text}
    </Typography>
  </Grid>
);