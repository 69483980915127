import React from 'react';
import { Grid } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import Icon from './Icon';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        img1: imageSharp (fluid: { originalName: { eq: "UsedBy-1.png" }}) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img2: imageSharp (fluid: { originalName: { eq: "UsedBy-2.png" }}) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img3: imageSharp (fluid: { originalName: { eq: "UsedBy-3.png" }}) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img4: imageSharp (fluid: { originalName: { eq: "UsedBy-4.png" }}) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img5: imageSharp (fluid: { originalName: { eq: "UsedBy-5.png" }}) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img6: imageSharp (fluid: { originalName: { eq: "UsedBy-6.png" }}) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img7: imageSharp (fluid: { originalName: { eq: "UsedBy-7.png" }}) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img8: imageSharp (fluid: { originalName: { eq: "UsedBy-8.png" }}) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={data => (
      <SectionWrapper className={styles.container}>
        <div className={styles.headingContainer}>
          <Title variant='h4' className={styles.titleContainer} weight='bold'>      
            {i18n('Customers across all industries')}
          </Title>
          <Subtitle color='main' className={styles.subtitleText}>
            {i18n('We help all businesses, regardless of size, to track their vehicles, reduce costs, increase productivity, increase safety, coordinate better repair and maintenance, and more.')}
          </Subtitle>
        </div>
        <Grid container className={styles.iconsContainer}>
          <Icon img={data['img1']}
            text={i18n('Craftsmen')}/>
          <Icon img={data['img2']} delay={100}
            text={i18n('Leasing & rental')}/>
          <Icon img={data['img3']} delay={200}
            text={i18n('Wholesalers & retailers')}/>
          <Icon img={data['img4']} delay={300}
            text={i18n('Plumbing')}/>
          <Icon img={data['img5']}
           text={i18n('Transport & logistics')}/>
          <Icon img={data['img6']} delay={100}
            text={i18n('State & municipality')}/>
          <Icon img={data['img7']} delay={200}
            text={i18n('Agriculture')}/>
          <Icon img={data['img8']} delay={300}
            text={i18n('Sales & services')}/>
        </Grid>
      </SectionWrapper>
    )}/>
);