import React from 'react';
import { Grid } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Typography from '../../../../components/Typography';
import WhyIcon from './WhyIcon';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        img1: imageSharp(fluid: {
          originalName: {eq: "HowTo-1.png"}
        }) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img2: imageSharp(fluid: {
          originalName: {eq: "HowTo-2.png"}
        }) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img3: imageSharp(fluid: {
          originalName: {eq: "HowTo-3.png"}
        }) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img4: imageSharp(fluid: {
          originalName: {eq: "HowTo-4.png"}
        }) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ img1, img2, img3, img4 }) => (
      <SectionWrapper className={styles.container}>
        <Typography variant='h4'
          weight='bold'
          className={styles.titleContainer}>
          {i18n('Fleet is a simple to use fleet management solution for everyone within the company')}
        </Typography>
        <Grid container className={styles.contentContainer}>
          <WhyIcon fluid={img1.fluid}
            alt="connect your fleet to partner">
            {i18n('Manage all vehicles')}
          </WhyIcon>
          <WhyIcon fluid={img2.fluid}
            alt="access your car data">
            {i18n('Automate trip-logging')}
          </WhyIcon>
          <WhyIcon fluid={img3.fluid}
            alt="your mechanic will help you with supervision">
            {i18n('Receive personalized reports')}
          </WhyIcon>
          <WhyIcon fluid={img4.fluid}
            alt="your mechanic will help you with supervision">
            {i18n('Make repair and maintenance of cars easy')}
          </WhyIcon>
        </Grid>
      </SectionWrapper>
    )}/>
);